import {Form, message} from 'antd';
import {NumberPhone} from '../numberPhone/NumberPhone';
//import {FullName} from './components/FullName';
import {VerifiCode} from './components/VerifiCode';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {signUp, signIn} from './LoginSlice';
import {useSelector} from 'react-redux';

export const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/';
	const [form] = Form.useForm();
	const onFinishFailed = (errorInfo) => console.log(errorInfo);
	const isRegister = useSelector((state) => state.Login.isRegister);
	const role = useSelector((state) => state.Login.access);

	useEffect(() => {
		if (role) {
			message.warning({
				content: role,
				style: {
					marginTop: '10px',
				},
                duration: 10,
			});
		}
	}, [role]);

	const onFinish = (val) => {
		const res = {
			...val,
			redirect: () => navigate(fromPage, {replace: true}),
		};
		if (isRegister) {
			message.warning({
				content: 'УЗ не зарегистрирована. Обратитесь в тех. поддержку',
				style: {
					marginTop: '70px',
				},
                duration: 10,
			});
			//dispatch(signUp(res));
		} else {
			dispatch(signIn(res));
		}
	};

	return (
		<Form
			form={form}
			name="basic"
			labelCol={{
				span: 24,
			}}
			initialValues={{
				remember: true,
				prefix: '7',
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off">
			{/* {isRegister && <FullName />} */}
			<NumberPhone form={form} />
			<VerifiCode />
		</Form>
	);
};
