import {api} from 'services/api';

export const preActivationThunk = async (params, {rejectWithValue, dispatch, getState}) => {
    const st = getState().FormActivation.singleCheck;
    const phone = st.smart_home_owner_phone || params.phone;

	const body = {
		jsonrpc: '2.0',
		params: {
			phone_number: phone,
		},
	};

    if (st.checkEmpty) {
        let {firstName, lastName, patronyMic} = params;
    
        body.method = 'startSmartHomeGetAccount';
        body.params.owner_full_name = (lastName + firstName + patronyMic);
        body.params.owner_first_name = firstName;
        body.params.owner_last_name = lastName;
        body.params.owner_patronymic = patronyMic;
    } else {
        const fullName = st.owner_full_name.split(' ');

        body.method = 'startSmartHomeActivation';
        body.params.communal_account_id = st.id;
        body.params.owner_full_name = st.owner_full_name;
        body.params.owner_first_name = fullName[1];
        body.params.owner_last_name = fullName[0];
        body.params.owner_patronymic = fullName[2];
    }
 
	try {
		const {data} = await api.post('/ccms', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
