import {api} from 'services/api';

export const getBuildingsThunk = async (org_id, {rejectWithValue, dispatch, getState}) => {

    const st = getState().Login.user;
    const stLocal = JSON.parse(localStorage.getItem('user'));
    const account_id = st && st.id || stLocal.id;

	const body = {
		jsonrpc: '2.0',
		method: 'listSmartHomeBuildings',
		params: {
            account_id,
            org_id,
		},
	};

	try {
		const {data} = await api.post('/mcrm', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
