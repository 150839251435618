import {Progress} from 'antd';
import React, {useEffect, useState, useCallback} from 'react';

let timeoutID;

export const TimerProgress = (opt) => {
	const {sec, startTimer, stopTimer, progressShow, cb} = opt;
	const [percent, setPercent] = useState(100);
	const delay = (sec * 1000) / 100;

	const stop = () => {
		clearInterval(timeoutID);
		setPercent(100);
	};

	const start = () => {
		let full = 100;

		timeoutID = setInterval(() => {
			if (!full) {
				stop();
				cb();
				return;
			}

			full--;
			setPercent((prev) => prev - 1);
		}, delay);
	};

	useEffect(() => {
		startTimer.current = start;
		stopTimer.current = stop;
	}, []);

	return (
		<>
			{progressShow ? (
				<Progress
					strokeColor="#ff4d4f"
					percent={percent}
					status="normal"
					className="cust-transition-1s px-1 pb-5"
				/>
			) : null}
		</>
	);
};
