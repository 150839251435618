export const errActivation = (err) => {

    if (err.code === '409') return 'Неверный код';

	switch (err.message) {
        case 'SMART_HOME_ACTIVATION_ALREADY_DONE':
            return 'Активация умного дома по указанному номеру телефона была выполнена ранее.';
        case 'CURRENT_PDA_EDITION_ERR':
            return 'Активация не выполнена. Обратитесь в техническую поддержку.'
        case 'SMART_HOME_ACTIVATION_ERR':
            return 'Активация не выполнена. Сервис не отвечает. Обратитесь в техническую поддержку.'
        case 'VERIFICATION_CODE_EMPTY':
            return 'Проверочный код введен некорректно. Попробуйте ввести снова или повторно нажмите «Выслать код».'
        case 'SESSION_ID_ERR':
            return 'Ошибка выполнения запроса. Попробуйте выполнить отправку позже.'
        case 'NUMBER_OF_ATTEMPS_TO_ENTER_VERIFICATION_CODE_EXCEEDED':
            return 'Количество попыток ввода исчерпано. Нажмите «Выслать код» повторно.'
        case 'PHONE_NUMBER_EMPTY':
            return 'Не заполнен номер телефона. Заполните поле «Телефон» и нажмите «Выслать код».'
        case 'COMMUNAL_ACCOUNT_EMPTY':
        case 'COMMUNAL_ACCOUNT_ID':
            return 'Не выбран лицевой счет. Укажите в поле лицевой счет.'
        case 'PHONE_NUMBER_INVALID':
            return 'Неверно введен номер телефона. Проверьте корректность ввода данных и попробуйте снова.'
        case 'COMMUNAL_ACCOUNT_ERR':
            return 'По указанным данным не найдено соответствие лицевому счету. Обратитесь в техническую поддержку.'
        default:
            return 'Ошибка повторите позже.'
    }
};
