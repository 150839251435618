import {configureStore} from '@reduxjs/toolkit';
import {NumberPhone} from 'features/auth/numberPhone/NumberPhoneSlice';
import {Login} from 'features/auth/login/LoginSlice';
import {UpdateAccessTokens} from 'store/updateAccessTokens/updateAccessTokensSlice';
import {FormActivation} from 'features/formActivation/FormActivationSlice';

export const store = configureStore({
	reducer: {
		NumberPhone,
		Login,
		UpdateAccessTokens,
		FormActivation,
	},
	devTools: true,
});
