import {Form, Input} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

export const FormOwner = ({form}) => {
	const list = useSelector((state) => state.FormActivation.singleCheck);
    const [isShowOwnerInput, setShowOwnerInput] = useState(true);

    const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);
	useEffect(() => {
		if (!isShowOwnerInput) {
			setShowOwnerInput(true);
		}
	}, [getDefaultDisabled]);

	useEffect(() => {
		if (list) {
            if (list.owner_full_name) {
                form.setFieldValue('owner', list.owner_full_name);
            } else {
                setShowOwnerInput(false);
            }
		}
	}, [list]);

    const Owner = function () {
        return <Form.Item
			name="owner"
			label="Собственник"
			rules={[
				{
					required: true,
					message: 'Введите номер телефона!',
				},
			]}>
			<Input disabled={true} prefix={<UserOutlined className="site-form-item-icon" />} />
		</Form.Item>
    }

    return (<>
        {isShowOwnerInput ? <Owner /> : null}
    </>)
};
