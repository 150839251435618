import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getOrganizationsThunk} from './thunk/getOrganizations';
import {getBuildingsThunk} from './thunk/getBuildings';
import {getChecksThunk} from './thunk/getChecks';
import {activationThunk} from './thunk/activation';
import {connectionThunk} from './thunk/сonnection';
import {preActivationThunk} from './thunk/preActivation';
import {getRoomsThunk} from './thunk/getRooms';

const initialState = {
	organizations: null,
	buildings: null,
	rooms: null,
	checks: null,
	reId: null,
	singleCheck: null,
	preActivation: null,
	postActivation: null,
	defaultInput: false,
    unlockInputPhone: false,
};

export const getOrganizations = createAsyncThunk('form/organizations', getOrganizationsThunk);
export const getBuildings = createAsyncThunk('form/buildings', getBuildingsThunk);
export const getRooms = createAsyncThunk('form/rooms', getRoomsThunk);
export const getChecks = createAsyncThunk('form/checks', getChecksThunk);
export const preActivation = createAsyncThunk('form/preAct', preActivationThunk);
export const activation = createAsyncThunk('form/code', activationThunk);
export const connection = createAsyncThunk('form/connection', connectionThunk);

export const FormActivationSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		selectCheck: (state, action) => {
			state.singleCheck = JSON.parse(action.payload);
		},
		defaultLockInputs: (state, action) => {
			state.defaultInput = !state.defaultInput;
			state.singleCheck = null;
		},
		unlockInput: (state, action) => {
            if (action.payload === 'phone') {
                state.unlockInputPhone = !state.unlockInputPhone;
            }
		},
		saveReId: (state, action) => {
            state.reId = action.payload;
		},
	},
	extraReducers: {
		[getOrganizations.fulfilled]: (state, action) => {
			state.organizations = action.payload;
		},
		[getBuildings.fulfilled]: (state, action) => {
			state.buildings = action.payload;
		},
		[getRooms.fulfilled]: (state, action) => {
			state.rooms = action.payload;
		},
		[getChecks.fulfilled]: (state, action) => {
			state.checks = action.payload;
		},
		[preActivation.fulfilled]: (state, action) => {
			state.preActivation = action.payload;
		},
		[activation.fulfilled]: (state, action) => {
			state.postActivation = action.payload;
		},
	},
});

export const {selectCheck, defaultLockInputs, unlockInput, saveReId} = FormActivationSlice.actions;
export const FormActivation = FormActivationSlice.reducer;
