import {Form, Input} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

export const FormName = ({prop}) => {
	const list = useSelector((state) => state.FormActivation.singleCheck);
    const [isShowNameInput, setShowNameInput] = useState(false);

    const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);
	useEffect(() => {
		if (isShowNameInput) {
			setShowNameInput(false);
		}
	}, [getDefaultDisabled]);

    const fullName = {
        firstName: {
            label: 'Имя',
            message: 'Введите имя!',
        },
        lastName: {
            label: 'Фамилия',
            message: 'Введите фамилию!',
        },
        patronyMic: {
            label: 'Отчество',
            message: 'Введите отчество!',
        }
    }

	useEffect(() => {
		if (list && list.checkEmpty) {
            setShowNameInput(true);
		}
	}, [list]);

    if (!fullName[prop]) return null

    const Name = function () {
        return <Form.Item
			name={prop}
			label={fullName[prop].label}
			rules={[
				{
					required: true,
					message: fullName[prop].message,
				},
			]}>
			<Input prefix={<UserOutlined className="site-form-item-icon" />} />
		</Form.Item>
    }

    return (<>
        {isShowNameInput ? <Name /> : null}
    </>)
};
