import {api} from 'services/api';

export const getRoomsThunk = async (id, {rejectWithValue, dispatch, getState}) => {
	const body = {
		jsonrpc: '2.0',
		method: 'listSmartHomeRealEstates',
		params: {
			filter: {
				field: 'building_id',
				operator: '=',
				value: id,
			},
		},
	};

	try {
		const {data} = await api.post('/mcrm', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
