import {Form, Select} from 'antd';
import {TeamOutlined} from '@ant-design/icons';
import {createElement} from 'react';
import {useEffect, useState} from 'react';
import {listOrganizations} from '../FormActivSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {getOrganizations, getBuildings} from '../FormActivationSlice';

export const FormOrganization = () => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);

	const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);
	useEffect(() => {
		if (disabled) {
			setDisabled(false);
		}
	}, [getDefaultDisabled]);

	const data = useSelector(listOrganizations);

	useEffect(() => {
		dispatch(getOrganizations());
	}, [dispatch]);

	const onChange = (val) => {
		dispatch(getBuildings(val));
		setDisabled(true);
	};

	return (
		<Form.Item
			name="organization"
			label="Организация"
			rules={[{required: true, message: 'Выберите организацию!'}]}>
			<Select
				disabled={disabled}
				showSearch
				placeholder={createElement(TeamOutlined)}
				optionFilterProp="children"
				onChange={onChange}
				filterOption={(input, option) =>
					(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
				}
				options={data}
			/>
		</Form.Item>
	);
};
