import {Form, Col, Row, message} from 'antd';
import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormOrganization} from './components/FormOrganization';
import {FormBuilding} from './components/FormBuilding';
import {FormCheck} from './components/FormCheck';
import {FormCode} from './components/FormCode';
import {FormPhone} from './components/FormPhone';
import {FormOwner} from './components/FormOwner';
import {FormName} from './components/FormName';
import {FormRoom} from './components/FormRoom';
import {activation, defaultLockInputs, connection} from './FormActivationSlice';
import {errActivation} from 'services/getErr';

export const FormActivation = ({btnReset}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const lockCodeInput = useRef(null);
	const postActivation = useSelector((state) => state.FormActivation.postActivation);
	const onFinishFailed = (errorInfo) => console.log(errorInfo);
	const onFinish = (val) => dispatch(activation(val));

	const reset = () => {
		form.resetFields();
		dispatch(defaultLockInputs());
	};

	useEffect(() => {
		btnReset.current = reset;
	}, []);

	useEffect(() => {
		if (postActivation) {
            const err = postActivation.error;

			if (err) {
                if (err.code === '419') {
                    lockCodeInput.current();
                }
                message.error({
                    className: 'notify',
                    content: errActivation(err),
                    duration: 10,
                });

			} else {
                reset();
                lockCodeInput.current();
                message.success({
                    className: 'notify',
                    content: 'Активация выполнена',
                    duration: 10,
                });
                dispatch(connection());
            }
		}
	}, [postActivation]);

	return (
		<Form
			className="max-w-[800px] mx-auto"
			form={form}
			name="basic"
			labelCol={{
				span: 24,
			}}
			initialValues={{
				remember: true,
				prefix: '7',
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off">

			<Row gutter={[32, 0]}>
				<Col xs={24} sm={12}>
					<FormOrganization />
				</Col>
				<Col xs={24} sm={12}>
					<FormBuilding />
				</Col>
				<Col xs={24} sm={12}>
					<FormRoom />
				</Col>
				<Col xs={24} sm={12}>
					<FormCheck form={form} />
				</Col>
				<Col xs={24} sm={12}>
					<FormOwner form={form} />
					<FormName prop='firstName' />
				</Col>
                <Col xs={24} sm={12}>
					<FormName prop='lastName' />
				</Col>
                <Col xs={24} sm={12}>
					<FormName prop='patronyMic' />
				</Col>
			</Row>

			<Row gutter={[32, 0]}>
				<Col xs={24} sm={12}>
					<FormPhone form={form} />
				</Col>
				<Col xs={24} sm={12}>
					<FormCode form={form} lockCodeInput={lockCodeInput} />
				</Col>
			</Row>
		</Form>
	);
};
