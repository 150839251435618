import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {store} from 'store';
import {updateAccess} from '../store/updateAccessTokens/updateAccessTokensSlice';
import {url} from './url';
import {history} from 'services/history';

export const api = axios.create({
	baseURL: url.BASE_URL,
});

/**
 * Проверка токена
 * Если токен истек возвращает false
 * @param {string} token
 * @returns {boolean}
 */
export const validateToken = (token) => {
	const jwt = jwt_decode(token);
    const now = new Date();
    const exp = new Date(jwt.exp * 1000);
	const diff = exp - now;
    const ms = diff;
    const sec = ms / 1000;
    const min = sec / 60;
    const hour = min / 60;
    const day = hour / 24;
    
    if (min-10 > 0) {
        return true
    }

	return false; 
};

api.interceptors.request.use(async (config) => {
	const accessToken = localStorage.getItem('accessToken');
	const refreshToken = localStorage.getItem('refreshToken');

	if (!accessToken || !refreshToken) return config;
	if (validateToken(accessToken)) return config;

    await store.dispatch(updateAccess());

    if (config.data?.method === 'auth.sendCode') {
        history.push('/');
        history.go(0);
        throw new axios.Cancel('auth redirect');
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});
