import {MailOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Button, Form, Input, Col, Row, Tooltip} from 'antd';
import {useSelector} from 'react-redux';

export const VerifiCode = () => {
	const {loading} = useSelector((state) => state.Login);

	return (
		<>
			<Form.Item
				name="code"
				label="Смс код"
				rules={[
					{
						required: true,
						message: '',
					},
				]}>
				<Form.Item
					name="code"
					noStyle
					rules={[{required: true, message: 'Введите смс код!'}]}>
					<Input
						placeholder="проверочный код"
						prefix={<MailOutlined className="site-form-item-icon" />}
						suffix={
							<Tooltip title="Код будет отправлен на ваш номер">
								<InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}} />
							</Tooltip>
						}
					/>
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<Row>
					<Col xs={24} sm={10}>
						<Button type="primary" htmlType="submit" block loading={loading}>
							Войти
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</>
	);
};
