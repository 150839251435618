import {Col, Row} from 'antd';
import {Login} from 'features/auth/login/Login';

export const LoginPage = () => {

	return (
		<div className="absolute h-full w-full">
			<Row justify="center" className="relative top-2/4 -translate-y-2/4">
				<Col xs={22} sm={16} md={10} lg={8} xl={5}>
					<h1 className="text-center mb-8 text-h2">Авторизация</h1>
					<Login />
				</Col>
			</Row>
		</div>
	);
};
