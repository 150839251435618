import {Link, useNavigate} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {isAuth, signOut} from 'features/auth/login/LoginSlice';
import {useDispatch, useSelector} from 'react-redux';
import logo from 'assets/logo-red.svg';
import {createElement} from 'react';

const {Header} = Layout;

export const PageHeader = () => {
	const dispatch = useDispatch();
	const checkAuth = useSelector(isAuth);
	const navigate = useNavigate();

	const menuHandler = (e) => {
		dispatch(signOut());
		navigate(0);
	};

	const items = [
		checkAuth && {
			label: 'Выход',
			key: '/login',
			title: 'Выход',
			icon: createElement(ArrowLeftOutlined),
		},
		/* { label: 'Портал', key: '/', },
        { label: 'Вход', key: '/login' }, */
	];

	return (
		<Header className="flex items-center bg-transparent">
			<Link to="/" className="absolute left-1/2 -translate-x-2/4 block cursor-pointer z-10">
				<img src={logo} className="h-[30px] logo" />
			</Link>
			<Menu items={items} onClick={menuHandler} mode="horizontal" />
		</Header>
	);
};
