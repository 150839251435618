import {api} from 'services/api';

export const checkPhoneThunk = async (phone, {rejectWithValue, dispatch, getState}) => {
	const body = {
		jsonrpc: '2.0',
		method: 'auth.checkPhone',
		params: {
			phone_number: phone,
		},
	};

	try {
		const {data} = await api.post('/auth', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
