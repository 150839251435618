import {PhoneOutlined, RetweetOutlined} from '@ant-design/icons';
import {Button, Form, Input, Col, Row, Select} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {resendCode, sendCode} from 'features/auth/numberPhone/NumberPhoneSlice';
import {useDispatch, useSelector} from 'react-redux';

export const NumberPhone = ({form}) => {
	const dispatch = useDispatch();
	const [btnResend, setBtnResend] = useState(false);
	const [loadings, setLoadings] = useState(false);
	const {isLoading} = useSelector((state) => state.NumberPhone);
	const textBtnToggle = isLoading ? 'Прервать' : 'Выслать код';

	useEffect(() => {
		setLoadings(isLoading);
	}, [isLoading]);

	const prefixPhone = 7;
	const inputPhone = useRef(null);

	const getPhone = () => {
		const phone = inputPhone.current.input.value.trim();
		if (phone) {
			return prefixPhone + phone;
		}
		return null;
	};

	const handleSendCode = () => {
		if (!getPhone()) {
			form.setFields([
				{
					name: 'phone',
					errors: ['Введите номер телефона!'],
				},
			]);
			return;
		}

		dispatch(sendCode(getPhone()));
		setBtnResend(true);
	};

	const handleResendCode = () => {
		if (getPhone()) {
			dispatch(resendCode(getPhone()));
		}
	};

	const {Option} = Select;

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{width: 70}}>
				<Option value={prefixPhone}>7</Option>
			</Select>
		</Form.Item>
	);

	return (
		<>
			<Form.Item
				name="phone"
				label="Телефон"
				rules={[
					{
						required: true,
						message: 'Введите номер телефона!',
					},
				]}>
				<Input
					ref={inputPhone}
					prefix={<PhoneOutlined className="site-form-item-icon" />}
					placeholder="номер"
					addonBefore={prefixSelector}
				/>
			</Form.Item>

			<Form.Item>
				<Row gutter={[0, 24]}>
					<Col xs={24} sm={10}>
						<div onClick={handleSendCode}>
							<Button block loading={loadings} className="cursor-pointer">
								{textBtnToggle}
							</Button>
						</div>
					</Col>
					<Col xs={24} sm={{span: 10, offset: 4}}>
						{btnResend && (
							<div onClick={handleResendCode}>
								<Button block className="cursor-pointer">
									<RetweetOutlined className="align-baseline" /> Повторно
								</Button>
							</div>
						)}
					</Col>
				</Row>
			</Form.Item>
		</>
	);
};
