import {Layout} from 'antd';
import {Outlet} from 'react-router-dom';

const {Content} = Layout;

export const PageContent = () => {
	return (
		<Content className="relative">
			<Outlet />
		</Content>
	);
};
