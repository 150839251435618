import {api} from 'services/api';

export const resendCodeThunk = async (phone, {rejectWithValue, dispatch, getState}) => {
	const st = getState().NumberPhone;
	const locPhoneСodeHash = localStorage.getItem('phone_code_hash');
	const body = {
		jsonrpc: '2.0',
		method: 'auth.resendCode',
		params: {
			phone_number: phone,
			phone_code_hash: st.phoneСodeHash || locPhoneСodeHash,
		},
	};

	try {
		const {data} = await api.post('/ccms', body);
	
		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
