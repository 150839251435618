import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {validateToken} from 'services/api';
import {signInThunk} from './thunk/signIn';

const initialState = {
	accessToken: null,
	refreshToken: null,
	firstName: null,
	lastName: null,
	phoneCode: null,
	isRegister: false,
	user: null,
	loading: false,
	access: null,
};

export const signIn = createAsyncThunk('login/signIn', signInThunk);

export const LoginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		saveToken: (state, action) => {
			const checkRoleErr = action.payload.result.user.err;
			if (checkRoleErr) {
				state.access = 'Не достаточно прав доступа';
				return;
			}
			state.user = action.payload.result.user;
			state.accessToken = action.payload.result.access_token;
			state.refreshToken = action.payload.result.refresh_token;
			localStorage.setItem('user', JSON.stringify(state.user));
			localStorage.setItem('accessToken', state.accessToken);
			localStorage.setItem('refreshToken', state.refreshToken);
		},
		enableRegister: (state, action) => {
			state.isRegister = true;
		},
		signOut: (state, action) => {
			state.accessToken = null;
			state.refreshToken = null;
			state.user = null;
			localStorage.removeItem('phoneCodeHash');
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
		},
	},
	extraReducers: {
		[signIn.pending]: (state, action) => {
			state.access = null;
			state.loading = true;
		},
		[signIn.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[signIn.rejected]: (state, action) => {
			state.loading = false;
			state.access = 'Попробуйте повторить попытку позже';
		},
	},
});

const selectSelf = (state) => state;

export const isAuth = createSelector(selectSelf, (state) => {
	const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return false;
	return validateToken(accessToken);
});

export const {saveToken, enableRegister, signOut} = LoginSlice.actions;
export const Login = LoginSlice.reducer;
