import {Layout} from 'antd';

const {Footer} = Layout;

export const PageFooter = () => {
	const year = new Date().getFullYear();
	//text-[#909090] bg-[#e6e6e6]
	return (
		<Footer className="text-center text-[#828282] bg-transparent">
			&copy; Copyright {year}, LSR Group
		</Footer>
	);
};
