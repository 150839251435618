import {Layout} from 'antd';
import {PageHeader} from './PageHeader';
import {PageContent} from './PageContent';
import {PageFooter} from './PageFooter';
import {useLocation} from 'react-router-dom';

export const PageLayout = () => {
	const location = useLocation();
	const login = location.pathname === '/login';
	const singleLayout = login ? '  singleLayout' : '';

	return (
		<Layout className={`min-h-[100vh] overflow-auto ${singleLayout}`}>
			<PageHeader />
			<PageContent />
			<PageFooter />
		</Layout>
	);
};
