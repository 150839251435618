import {api} from 'services/api';

export const connectionThunk = async (params, {rejectWithValue, dispatch, getState}) => {
    const st = getState().FormActivation;
	const account_id = st.postActivation.result.account_id;
    const re_id = st.reId;

	const body = {
		jsonrpc: '2.0',
		method: 'createSmartHomeConnection',
		params: {
			account_id,
			re_id,
		},
	};

	try {
		const {data} = await api.post('/mcrm', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
