import {createSelector} from '@reduxjs/toolkit';

const organizations = (state) => state.FormActivation.organizations;
const buildings = (state) => state.FormActivation.buildings;
const rooms = (state) => state.FormActivation.rooms;
const checks = (state) => state.FormActivation.checks;

export const listOrganizations = createSelector(organizations, (state) => {
	if (!state) return null;
	return state.result.data.map((item, i) => ({
		label: item.name,
		value: item.id,
		key: i,
	}));
});
export const listBuildings = createSelector(buildings, (state) => {
	if (!state) return null;
	return state.result.data.map((item, i) => ({
		label: item.name,
		value: item.id,
		key: i,
	}));
});
export const listRooms = createSelector(rooms, (state) => {
	if (!state) return null;
	return state.result.data.map((item, i) => ({
		label: item.name,
		value: item.id,
		key: i,
	}));
});
export const listChecks = createSelector(checks, (state) => {
	if (!state) return null;
	return state.result.data.map((item, i) => ({
		label: item.name,
		value: JSON.stringify(item),
		key: i,
	}));
});
