import {enableRegister, saveToken} from '../LoginSlice';
import {api} from 'services/api';

export const signInThunk = async (params, {rejectWithValue, dispatch, getState}) => {
	const {code, phone, prefix, redirect} = params;
	const st = getState().NumberPhone;
	const locPhoneСodeHash = localStorage.getItem('phone_code_hash');
	const body = {
		jsonrpc: '2.0',
		method: 'auth.signIn',
		params: {
			phone_number: prefix + phone,
			phone_code_hash: st.phoneСodeHash || locPhoneСodeHash,
			phone_code: code,
		},
	};

	try {
		const {data} = await api.post('/signin', body);

		const err = data.error;

		if (err) {
			if (err.message === 'PHONE_NUMBER_UNOCCUPIED') {
				dispatch(enableRegister());
			}

			console.log('signInErr');
			return;
		}

		dispatch(saveToken(data));

		if (!getState().Login.access) {
			redirect();
		}

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
