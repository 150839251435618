import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from 'store';
import App from 'App';
import 'styles/tailwind-base.css';
import 'styles/tailwind-components.css';
import 'styles/antd.scss';
import 'styles/tailwind-utilities.css';
import 'styles/font.css';
import 'styles/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
