import {Form, Select} from 'antd';
import {BankOutlined} from '@ant-design/icons';
import {createElement} from 'react';
import {useEffect, useState} from 'react';
import {listBuildings} from '../FormActivSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {getRooms} from '../FormActivationSlice';

export const FormBuilding = () => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(true);
	const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);
	useEffect(() => {
		if (!disabled) {
			setDisabled(true);
		}
	}, [getDefaultDisabled]);
	const data = useSelector(listBuildings);

	useEffect(() => {
		if (data) {
			setDisabled(false);
		}
	}, [data]);

	const onChange = (val) => {
		dispatch(getRooms(val));
		setDisabled(true);
	};

	return (
		<Form.Item
			name="building"
			label="Здание"
			rules={[{required: true, message: 'Выберите здание!'}]}>
			<Select
				disabled={disabled}
				showSearch
				placeholder={createElement(BankOutlined)}
				optionFilterProp="children"
				onChange={onChange}
				filterOption={(input, option) =>
					(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
				}
				options={data}
			/>
		</Form.Item>
	);
};
