import {Button, Tooltip} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import {useRef} from 'react';
import {FormActivation} from 'features/formActivation/FormActivation';

export const HomePage = () => {
	const btnReset = useRef(null);

	const reset = () => {
		btnReset.current();
	};

	return (
		<div className="px-6 py-16 md:p-16 absolute h-full w-full flex flex-col xs:justify-center overflow-auto">
			<h1 className="mb-10 text-center">
				Форма активации <br />
				Умного дома
			</h1>

			<div className="mx-auto w-[800px] max-w-full mb-4 flex justify-end">
				<Tooltip title="Сбросить все заполненные поля">
					<Button onClick={reset} type="link" icon={<ReloadOutlined />} className="reset">
						Сброс
					</Button>
				</Tooltip>
			</div>

			<FormActivation btnReset={btnReset} />
		</div>
	);
};
