import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {sendCodeThunk} from './thunk/sendCode';
import {resendCodeThunk} from './thunk/resendCode';
import {cancelCodeThunk} from './thunk/cancelCode';
import {checkPhoneThunk} from './thunk/checkPhone';

const initialState = {
	phoneNumber: null,
	phoneСodeHash: null,
	isLoading: false,
};

export const sendCode = createAsyncThunk(
	'phone/sendCode',
	async (phone, {rejectWithValue, dispatch, getState}) => {
		const st = getState().NumberPhone;
		const statePhone = st.phoneNumber;
		const stateHash = st.phoneСodeHash;
		const stateIsLoading = st.isLoading;

		if (stateIsLoading) {
			return await cancelCodeThunk(statePhone, stateHash, rejectWithValue);
		}

		dispatch(startLoading());

		dispatch(savePhoneNumber(phone));

		return await sendCodeThunk(phone, rejectWithValue);
	},
);

export const resendCode = createAsyncThunk('phone/resendCode', resendCodeThunk);
export const checkPhone = createAsyncThunk('phone/checkPhone', checkPhoneThunk);

export const NumberPhoneSlice = createSlice({
	name: 'phone',
	initialState,
	reducers: {
		savePhoneNumber: (state, action) => {
			state.phoneNumber = action.payload;
		},
		startLoading: (state) => {
			state.isLoading = true;
		},
	},
	extraReducers: {
		[sendCode.pending]: (state, action) => {},
		[sendCode.fulfilled]: (state, action) => {
			if (action.payload.result.phone_code_hash) {
				state.phoneСodeHash = action.payload.result.phone_code_hash;
				localStorage.setItem('phone_code_hash', action.payload.result.phone_code_hash);
			}
			state.isLoading = false;
		},
		[sendCode.rejected]: (state, action) => {
			console.log('reject');
			state.isLoading = false;
		},
	},
});

export const {savePhoneNumber, startLoading} = NumberPhoneSlice.actions;
export const NumberPhone = NumberPhoneSlice.reducer;
