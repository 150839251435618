import {api} from 'services/api';

export const getChecksThunk = async (id, {rejectWithValue, dispatch, getState}) => {
	const body = {
		jsonrpc: '2.0',
		method: 'listSmartHomeCommunalAccounts',
		params: {
			fields: ['owner_full_name', 'smart_home_owner_phone', 'smart_home_owner', 'smart_home_activation_date'],
			filter: {
				field: 're_id',
				operator: '=',
				value: id,
			},
		},
	};

	try {
		const {data} = await api.post('/mcrm', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
