import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ProtectedRoute} from 'services/ProtectedRoute';
import {PageLayout} from 'layout/PageLayout';
import {HomePage} from 'pages/HomePage';
import {LoginPage} from 'pages/loginPage/LoginPage';
import {Notfoundpage} from 'pages/NotFoundPage';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<PageLayout />}>
					<Route
						index
						element={
							<ProtectedRoute>
								<HomePage />
							</ProtectedRoute>
						}
					/>
					<Route path="/login" element={<LoginPage />} />
					<Route path="*" element={<Notfoundpage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
