import {api} from 'services/api';

export const activationThunk = async (params, {rejectWithValue, dispatch, getState}) => {
	const st = getState().FormActivation;
	const {code} = params;

	const body = {
		jsonrpc: '2.0',
		method: st.singleCheck.checkEmpty ? 'endSmartHomeGetAccount' : 'endSmartHomeActivation',
		params: {
			session_id: st.preActivation.result.session_id,
			verification_code: code,
		},
	};

	try {
		const {data} = await api.post('/ccms', body);

		return data;
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
