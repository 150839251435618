import {SendOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Button, Form, Input, Col, Row, Tooltip, message} from 'antd';
import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TimerProgress} from 'components/universal/TimerProgress';
import {unlockInput} from '../FormActivationSlice';
import {errActivation} from 'services/getErr';

export const FormCode = ({form, lockCodeInput}) => {

    const dispatch = useDispatch();
	const startTimer = useRef(null);
	const stopTimer = useRef(null);
	const [progressShow, setProgressShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
	const preActivation = useSelector((state) => state.FormActivation.preActivation);
    const postActivation = useSelector((state) => state.FormActivation.postActivation);
	const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);

	const unlockPhoneInput = () => {
        dispatch(unlockInput('phone'));
        stopTimer.current();
        setProgressShow(false);
    };

	const lockInput = () => {
		const inputVal = form.getFieldValue('code') || '';

		if (inputVal.trim()) {
            stopTimer.current();
			setProgressShow(false);
		}
	};

	useEffect(() => {
		lockCodeInput.current = lockInput;
	}, []);

	useEffect(() => {
        setProgressShow(false);
        setDisabled(false);
        stopTimer.current();
	}, [getDefaultDisabled]);

	useEffect(() => {
		if (preActivation) {
			const err = preActivation.error;

			if (err) {
                message.error({
                    className: 'notify',
                    content: errActivation(err),
                    duration: 10,
                });

			} else {
				setProgressShow(true);
				startTimer.current();

                message.success({
                    className: 'notify',
                    content: preActivation.result?.message,
                    duration: 10,
                });
			}
		}
	}, [preActivation]);

    // включаем доступность поля и кнопки 
    useEffect(() => {
		if (postActivation) {
			if (!postActivation.error) {
				setDisabled(true);
			}
		}
	}, [postActivation]);

	return (
		<>
			<Form.Item
				name="code"
				label="Код подтверждения"
				className="mb-[22px]"
				rules={[
					{
						required: true,
						message: 'Введите смс код!',
					},
				]}>
				<Input
                    disabled={disabled}
					prefix={<SendOutlined className="site-form-item-icon" />}
					suffix={
						<Tooltip title="Код подтверждения должен быть указан в течении минуты">
							<InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}} />
						</Tooltip>
					}
				/>
			</Form.Item>

			<Form.Item>
				<Row>
					<Col xs={24} className="min-h-[42px]">
						<TimerProgress
							sec={60}
							startTimer={startTimer}
							stopTimer={stopTimer}
							progressShow={progressShow}
							cb={unlockPhoneInput}
						/>
					</Col>

					<Col xs={24} sm={10}>
						<Button type="primary" htmlType="submit" block disabled={disabled}>
							Активировать
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</>
	);
};
