import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {history} from 'services/history';
import {url} from 'services/url';

export const updateAccess = createAsyncThunk(
	'token/update',
	async function (_, {rejectWithValue, dispatch, getState}) {

		const currentRefreshToken = localStorage.getItem('refreshToken');

		const body = {
			jsonrpc: '2.0',
			method: 'auth.updateAccessTokens',
			params: {
				refresh_token: currentRefreshToken,
			},
		};

		try {
			const {data} = await axios.post(url.BASE_URL + '/auth', body);
			if (data.error) throw new Error('Ошибка обновления токена');
			return data;
		} catch (error) {
			console.log(error);
			return rejectWithValue(error.message);
		}
	},
);

const initialState = {};

export const UpdateAccessTokensSlice = createSlice({
	name: 'token',
	initialState,
	extraReducers: {
		[updateAccess.pending]: (state, action) => {},
		[updateAccess.fulfilled]: (state, action) => {
            localStorage.setItem('accessToken', action.payload.result.access_token);
			localStorage.setItem('refreshToken', action.payload.result.refresh_token);
			// не следует здесь делать редирект, иначе обновление токена в api сломается
            // history.go(0);
		},
	},
});

export const UpdateAccessTokens = UpdateAccessTokensSlice.reducer;
