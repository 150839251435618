import {PhoneOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Button, Form, Input, Col, Row, Tooltip} from 'antd';
import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {preActivation} from '../FormActivationSlice';

export const FormPhone = ({form}) => {
	const dispatch = useDispatch();
	const [inputDisabled, setInputDisabled] = useState(true);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const personalAccount = useSelector((state) => state.FormActivation.singleCheck);
    const dataPreActivation = useSelector((state) => state.FormActivation.preActivation);
    const dataPostActivation = useSelector((state) => state.FormActivation.postActivation);
    const flagTimerEnd = useSelector((state) => state.FormActivation.unlockInputPhone);

    useEffect(() => {
		if (dataPreActivation) {
            // Слушаем окончание таймера при активации кода подтверждения,если пользователь за время работы таймера не успел ввести код то разблокируем поля номера для возможности повторной отправки смс
            setBtnDisabled(false);
            setInputDisabled(false);
		}
	}, [flagTimerEnd]);

    useEffect(() => {
		if (dataPreActivation) {
			const err = dataPreActivation.error;

            // Проверка если с сервера пришла ошибка неправильно указан номер, включаем доступ к полям номера
			if (err) {
                if (err.code === '406') {
                    setBtnDisabled(false);
			        setInputDisabled(false);
				} else {
			        setBtnDisabled(false);
                }
			}
		}
	}, [dataPreActivation]);

    useEffect(() => {
		if (dataPostActivation) {
			const err = dataPostActivation.error;

            // Проверка если с сервера пришла ошибка превышено число попыток для активации кода, включаем доступ к полям номера
			if (err) {
                if (err.code === '419') {
                    setBtnDisabled(false);
			        setInputDisabled(false);
				}
			}
		}
	}, [dataPostActivation]);

	useEffect(() => {
        // Слушаем когда придут данные по лицевому счету или когда пользователь выберет один, после включаем доступ к полям номера
		if (personalAccount) {
			if (personalAccount.smart_home_owner_phone) {
				form.setFieldValue('phone', personalAccount.smart_home_owner_phone);
			}
            setInputDisabled(false);
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
			setInputDisabled(true);
		}
	}, [personalAccount]);

    const inputsData = {
        phone: {
            name: 'phone',
            message: 'Введите номер телефона!'
        },
        firstName: {
            name: 'firstName',
            message: 'Введите имя!'
        },
        lastName: {
            name: 'lastName',
            message: 'Введите фамилию!'
        },
        patronyMic: {
            name: 'patronyMic',
            message: 'Введите отчество!'
        }
    }

    const validate = (field) => {
        const {name, message} = field;
        const getFields = form.getFieldsValue();

		if (getFields[name] && getFields[name].trim()) {
            return getFields[name];
        } else {
            form.setFields([
				{
					name: name,
					errors: [message],
				},
			]);
            return null;
        }
	};

	const handleSendCode = () => {
        const getFields = form.getFieldsValue();
        const hasFirstName = getFields.hasOwnProperty('firstName');
        const fields = {};

        fields.phone = validate(inputsData.phone);

        if (hasFirstName) {

            fields.firstName = validate(inputsData.firstName);
            fields.lastName = validate(inputsData.lastName);
            fields.patronyMic = validate(inputsData.patronyMic);

            if (!fields.phone) return;
            if (!fields.firstName) return;
            if (!fields.lastName) return;
            if (!fields.patronyMic) return;

            form.getFieldInstance('firstName').input.parentElement.classList.add('ant-input-affix-wrapper-disabled');
            form.getFieldInstance('lastName').input.parentElement.classList.add('ant-input-affix-wrapper-disabled');
            form.getFieldInstance('patronyMic').input.parentElement.classList.add('ant-input-affix-wrapper-disabled');
        }

        if (!fields.phone) return;

		dispatch(preActivation(fields));
		setBtnDisabled(true);
		setInputDisabled(true);
	};

	return (
		<>
			<Form.Item
				name="phone"
				label="Телефон"
				rules={[
					{
						required: true,
						message: 'Введите номер телефона!',
					},
				]}>
				<Input
					disabled={inputDisabled}
					prefix={<PhoneOutlined className="site-form-item-icon" />}
					placeholder="7 000 000 0000"
					suffix={
						<Tooltip title="На данный номер будет отправлен код подтверждения">
							<InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}} />
						</Tooltip>
					}
				/>
			</Form.Item>

			<Form.Item>
				<Row>
					<Col xs={24} sm={10}>
						<Button
							block
							disabled={btnDisabled}
							onClick={handleSendCode}
							className="mt-10">
							Выслать код
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</>
	);
};
