import {useSelector} from 'react-redux';
import {useLocation, Navigate} from 'react-router-dom';
import {isAuth} from 'features/auth/login/LoginSlice';

export const ProtectedRoute = ({children}) => {
	const location = useLocation();
	const checkAuth = useSelector(isAuth);

	if (!checkAuth) {
		return <Navigate to="/login" state={{from: location}} />;
	}

	return children;
};
