import {Form, Select, message} from 'antd';
import {AuditOutlined} from '@ant-design/icons';
import {createElement} from 'react';
import {useEffect, useState} from 'react';
import {listChecks} from '../FormActivSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {selectCheck} from '../FormActivationSlice';

export const FormCheck = ({form}) => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(true);
	const [required, setRequired] = useState(true);

	const getDefaultDisabled = useSelector((state) => state.FormActivation.defaultInput);
	useEffect(() => {
		if (!disabled) {
			setDisabled(true);
		}
	}, [getDefaultDisabled]);

	const data = useSelector(listChecks);

	useEffect(() => {
		if (data) {
            // Если лицевого счета нет
            if (data.length === 0) {
                const flag = JSON.stringify({
                    checkEmpty: true
                })
                setRequired(false);
                dispatch(selectCheck(flag));
            }

            // Если лицевой счет один
            if (data.length === 1) {
                let dataUser = (data[0].value);
                const isActiv = isActivation(dataUser);

                // Лицевой счет вставляем
                form.setFieldValue('check', dataUser);

                // Если активация для лицевого счета уже выполнена
                if (isActiv) {
                    const parseDataUser = JSON.parse(dataUser);
                    const phone = parseDataUser.smart_home_owner_phone;
                    const phoneMask = `7******${phone.substring(phone.length-4)}`;

                    parseDataUser.smart_home_owner_phone = '';
                    dataUser = JSON.stringify(parseDataUser);

                    message.error({
                        className: 'notify',
                        content: `Для лицевого счета выполнена активация Умного дома для номера телефона <${phoneMask}>`,
                        duration: 10,
                    });
                }

                dispatch(selectCheck(dataUser));
            }

            // Если лицевых счетов несколько
            if (data.length > 1) {
                setDisabled(false);
            }            
		}
	}, [data]);

	const onChange = (val) => {
		dispatch(selectCheck(val));
		setDisabled(true);
	};

	return (
		<Form.Item
			name="check"
			label="Лицевой счёт"
			rules={[{required, message: 'Выберите лицевой счёт!'}]}>
			<Select
				disabled={disabled}
				showSearch
				placeholder={createElement(AuditOutlined)}
				optionFilterProp="children"
				onChange={onChange}
				filterOption={(input, option) =>
					(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
				}
				options={data}
			/>
		</Form.Item>
	);
};

/**
 * Проверка активирована ли учетная запись
 * @param  {string} opt
 * @return {boolean}
 */
function isActivation(opt) {
    const data = JSON.parse(opt);
    const owner = data.smart_home_owner;
    const phone = data.smart_home_owner_phone;
    const date = data.smart_home_activation_date;
    
    if (!owner) return false;
    if (!phone) return false;
    if (date === '0001-01-01T00:00:00') return false;

    return true;
}
